var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [(_vm.redeem_report_month_by_day_loaded = true) ? _c('ChartRedeemReportMonthByDay', {
    attrs: {
      "data": _vm.redeemReportMonthByDay,
      "title": 'Redeem in Month by day'
    }
  }) : _vm._e(), _c('div', {
    staticClass: "pie-byday"
  }, [_c('b-form-select', {
    staticStyle: {
      "width": "50%"
    },
    attrs: {
      "options": _vm.chartReportAbsensiOptions
    },
    model: {
      value: _vm.chartReportAbsensiEcommerce,
      callback: function callback($$v) {
        _vm.chartReportAbsensiEcommerce = $$v;
      },
      expression: "chartReportAbsensiEcommerce"
    }
  })], 1), (_vm.absensireport_report_month_by_day_loaded = true && _vm.isChartReportMonthActive) ? _c('ChartRedeemReportMonthByDay', {
    attrs: {
      "data": _vm.absensireportReportMonthByDay,
      "title": 'Absensireport in Month by day'
    }
  }) : _vm._e(), (_vm.absensireport_report_month_by_week_loaded = true && !_vm.isChartReportMonthActive) ? _c('ChartReportMonthByWeek', {
    attrs: {
      "data": _vm.absensireportReportMonthByWeek,
      "title": 'Absensireport by Week',
      "height": 180
    }
  }) : _vm._e(), _c('div', {
    staticClass: "pie-byday"
  }, [_c('b-form-select', {
    staticStyle: {
      "width": "50%"
    },
    attrs: {
      "options": _vm.chartReportOptions
    },
    model: {
      value: _vm.chartReportEcommerce,
      callback: function callback($$v) {
        _vm.chartReportEcommerce = $$v;
      },
      expression: "chartReportEcommerce"
    }
  })], 1), (_vm.report_ecommerce_month_by_day_loaded = true && _vm.isChartMonthActive) ? _c('ChartReportEcommerceMonthByDay', {
    attrs: {
      "data": _vm.reportecommerceReportEcommerceMonthByDay,
      "title": 'Report Ecommerce by Day'
    }
  }) : _vm._e(), (_vm.report_ecommerce_month_by_week_loaded = true && !_vm.isChartMonthActive) ? _c('ChartReportEcommerceMonthByWeek', {
    attrs: {
      "data": _vm.reportecommerceReportEcommerceMonthByWeek,
      "title": 'Report Ecommerce by Week',
      "height": 180
    }
  }) : _vm._e(), _c('b-row', {
    staticClass: "justify-content-lg-start"
  }, [_c('b-col', {
    attrs: {
      "sm": "6",
      "lg": "4"
    }
  }, [_c('div', {
    staticClass: "pie-chart-container"
  }, [_c('ChartPie', {
    attrs: {
      "data": _vm.shorter_rekap,
      "title": 'Shorter Rekap',
      "height": 350
    }
  })], 1)]), _c('b-col', {
    attrs: {
      "sm": "6",
      "lg": "4"
    }
  }, [_c('div', {
    staticClass: "pie-chart-container-flex"
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.siswaStatusOptions
    },
    model: {
      value: _vm.siswaStatus,
      callback: function callback($$v) {
        _vm.siswaStatus = $$v;
      },
      expression: "siswaStatus"
    }
  }), _c('ChartPie', {
    attrs: {
      "data": _vm.siswaStatus,
      "title": 'Absensi Report by Status',
      "height": 350
    }
  })], 1)]), _c('b-col', {
    attrs: {
      "sm": "6",
      "lg": "4"
    }
  }, [_c('div', {
    staticClass: "pie-chart-container-flex"
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.siswaKeOptions
    },
    model: {
      value: _vm.siswaKe,
      callback: function callback($$v) {
        _vm.siswaKe = $$v;
      },
      expression: "siswaKe"
    }
  }), _c('ChartPie', {
    attrs: {
      "data": _vm.siswaKe,
      "title": 'Absensi Report by Ke',
      "height": 350
    }
  })], 1)]), _c('b-col', {
    attrs: {
      "sm": "6",
      "lg": "4"
    }
  }, [_c('div', {
    staticClass: "pie-chart-container-flex"
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.siswaReferralCodeFilledOptions
    },
    model: {
      value: _vm.siswaReferralCodeFilled,
      callback: function callback($$v) {
        _vm.siswaReferralCodeFilled = $$v;
      },
      expression: "siswaReferralCodeFilled"
    }
  }), _c('ChartPie', {
    attrs: {
      "data": _vm.siswaReferralCodeFilled,
      "title": 'Absensi Report by Referralcode Filled',
      "height": 350
    }
  })], 1)]), _c('b-col', {
    attrs: {
      "sm": "6",
      "lg": "4"
    }
  }, [_c('div', {
    staticClass: "pie-chart-container"
  }, [_c('ChartPie', {
    attrs: {
      "data": _vm.absensi_report_all_by_ecommerce,
      "title": 'Absensi Report by Ecommerce',
      "height": 250
    }
  })], 1)])], 1), _c('b-row', {
    staticClass: "justify-content-lg-start"
  }, [_c('b-col', {
    attrs: {
      "sm": "6",
      "lg": "4"
    }
  }, [_c('div', {
    staticClass: "pie-chart-containers"
  }, [_c('ChartPie', {
    attrs: {
      "data": _vm.absensi_report_all_by_kelas,
      "title": 'Absensi Report by Kelas',
      "height": 600
    }
  })], 1)])], 1), _c('b-row', {
    staticClass: "justify-content-lg-start"
  }, _vm._l(this.list_report_by_flag, function (item, indexTraining) {
    return _vm.report_by_flag_loaded == true ? _c('b-col', {
      key: item.id,
      attrs: {
        "sm": "6",
        "lg": "3"
      }
    }, [_c('b-card', {
      class: item.name == 'Total' ? 'bg-primary' : 'bg-info',
      attrs: {
        "no-body": ""
      }
    }, [_c('b-card-body', {
      staticClass: "pb-0"
    }, [_c('h4', {
      staticClass: "mb-0"
    }, [_vm._v(" " + _vm._s(item.name) + " Redeem: " + _vm._s(_vm._f("currency")(item.data.current.Total)) + " / " + _vm._s(_vm._f("currency")(item.data.past.Total)) + " ")]), _c('p', [_vm._v("Current / Previous Month")])]), item.name == 'Total' ? _c('card-bar-chart-redeem', {
      staticClass: "chart-wrapper px-3",
      staticStyle: {
        "height": "70px"
      },
      attrs: {
        "chartId": "card-chart-01",
        "height": 70,
        "redeem_data": item.data
      }
    }) : _c('card-bar-chart-redeem', {
      staticClass: "chart-wrapper px-3",
      staticStyle: {
        "height": "70px"
      },
      attrs: {
        "chartId": "card-chart-02",
        "height": 70,
        "redeem_data": item.data
      }
    })], 1)], 1) : _vm._e();
  }), 1), _c('b-row', {
    staticClass: "justify-content-lg-start"
  }, _vm._l(this.list_absensi_report_by_ecommerce, function (item, indexTraining) {
    return _vm.rekap_by_ecommerce_loaded == true ? _c('b-col', {
      key: item.id,
      attrs: {
        "sm": "6",
        "lg": "3"
      }
    }, [_c('b-card', {
      staticClass: "card",
      style: item.name == 'Total' ? 'background-color: #299c14' : 'background-color: #72c659',
      attrs: {
        "no-body": ""
      }
    }, [_c('b-card-body', {
      staticClass: "pb-0"
    }, [_c('h4', {
      staticClass: "mb-0"
    }, [_vm._v(" " + _vm._s(item.name) + " AbsensiReport: " + _vm._s(_vm._f("currency")(item.data.current.Total)) + " / " + _vm._s(_vm._f("currency")(item.data.past.Total)) + " ")]), _c('p', [_vm._v("Current / Previous Month")])]), item.name == 'Total' ? _c('card-bar-chart-absensi-report', {
      staticClass: "chart-wrapper px-3",
      staticStyle: {
        "height": "70px"
      },
      attrs: {
        "chartId": "card-chart-03",
        "height": 70,
        "absensireport_data": item.data
      }
    }) : _c('card-bar-chart-absensi-report', {
      staticClass: "chart-wrapper px-3",
      staticStyle: {
        "height": "70px"
      },
      attrs: {
        "chartId": "card-chart-04",
        "height": 70,
        "absensireport_data": item.data
      }
    })], 1)], 1) : _vm._e();
  }), 1), _c('div', {
    staticClass: "row",
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "lg": "12"
    }
  }, [_c('b', [_vm._v("List Redeem")]), _c('vue-good-table', {
    attrs: {
      "mode": "remote",
      "pagination-options": {
        // enabled: true,
        //mode: 'pages',
        perPage: 27,
        position: 'bottom',
        // perPageDropdown: [5, 10],
        dropdownAllowAll: false,
        // nextLabel: 'next',
        // prevLabel: 'prev',
        // rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of' // pageLabel: 'page', // for 'pages' mode
        // allLabel: 'All',

      },
      "totalRows": _vm.total_record_redeem,
      "rows": _vm.list_redeem,
      "columns": _vm.columns,
      "sort-options": {
        enabled: false
      },
      "row-style-class": _vm.rowStyleClassFn
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        return [_c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }])
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }