<script>
import { Bar } from 'vue-chartjs'
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips'

export default {
  extends: Bar,
  props: ['height','redeem_data'],
  data: function () {
    return {
      datasetsApi : [
        {
          label: 'Current',
          backgroundColor: 'rgba(244,255,62,0.3)',
          borderColor: 'transparent',
          data: [],
          labels: []
        },
          {
              label: 'previous',
              backgroundColor: 'rgba(33,25,255,0.3)',
              borderColor: 'transparent',
              data: [],
              labels: []
          },
      ],
    }
  },
  mounted () {
    let labelTemp = []
    for (let item of Object.keys(this.redeem_data.current)) {
        if (item !== 'Total') {
            this.datasetsApi[0].data.push(this.redeem_data.current[item])
            labelTemp.push(item)
        }
    }
      for (let item of Object.keys(this.redeem_data.past)) {
          if (item !== 'Total') {
              this.datasetsApi[1].data.push(this.redeem_data.past[item])
          }
      }

        this.renderChart(
          {
            labels:labelTemp,
            datasets: this.datasetsApi
          },
          {
            tooltips: {
              enabled: false,
              custom: CustomTooltips
            },
            maintainAspectRatio: false,
            legend: {
              display: false
            },
            scales: {
              xAxes: [{
                display: false,
                categoryPercentage: 1,
                barPercentage: 0.5
              }],
              yAxes: [{
                display: false
              }]
            }
          }
        )


  }
}
</script>
