<template>
  <div class="animated fadeIn">
    <ChartRedeemReportMonthByDay v-if="(redeem_report_month_by_day_loaded = true)" :data="redeemReportMonthByDay" :title="'Redeem in Month by day'" />
    <div class="pie-byday">
      <b-form-select v-model="chartReportAbsensiEcommerce" :options="chartReportAbsensiOptions" style="width: 50%"></b-form-select>
    </div>
    <ChartRedeemReportMonthByDay v-if="(absensireport_report_month_by_day_loaded = true && isChartReportMonthActive)" :data="absensireportReportMonthByDay" :title="'Absensireport in Month by day'" />
    <ChartReportMonthByWeek
      v-if="(absensireport_report_month_by_week_loaded = true && !isChartReportMonthActive)"
      :data="absensireportReportMonthByWeek"
      :title="'Absensireport by Week'"
      :height="180"
    />
    <div class="pie-byday">
      <b-form-select v-model="chartReportEcommerce" :options="chartReportOptions" style="width: 50%"> </b-form-select>
    </div>
    <ChartReportEcommerceMonthByDay v-if="(report_ecommerce_month_by_day_loaded = true && isChartMonthActive)" :data="reportecommerceReportEcommerceMonthByDay" :title="'Report Ecommerce by Day'" />
    <ChartReportEcommerceMonthByWeek
      v-if="(report_ecommerce_month_by_week_loaded = true && !isChartMonthActive)"
      :data="reportecommerceReportEcommerceMonthByWeek"
      :title="'Report Ecommerce by Week'"
      :height="180"
    />
    <b-row class="justify-content-lg-start">
      <b-col sm="6" lg="4">
        <div class="pie-chart-container">
          <ChartPie :data="shorter_rekap" :title="'Shorter Rekap'" :height="350" />
        </div>
      </b-col>
      <b-col sm="6" lg="4">
        <div class="pie-chart-container-flex">
          <b-form-select v-model="siswaStatus" :options="siswaStatusOptions"></b-form-select>
          <ChartPie :data="siswaStatus" :title="'Absensi Report by Status'" :height="350" />
        </div>
      </b-col>
      <b-col sm="6" lg="4">
        <div class="pie-chart-container-flex">
          <b-form-select v-model="siswaKe" :options="siswaKeOptions"></b-form-select>
          <ChartPie :data="siswaKe" :title="'Absensi Report by Ke'" :height="350" />
        </div>
      </b-col>
      <b-col sm="6" lg="4">
        <div class="pie-chart-container-flex">
          <b-form-select v-model="siswaReferralCodeFilled" :options="siswaReferralCodeFilledOptions"></b-form-select>
          <ChartPie :data="siswaReferralCodeFilled" :title="'Absensi Report by Referralcode Filled'" :height="350" />
        </div>
      </b-col>
      <b-col sm="6" lg="4">
        <div class="pie-chart-container">
          <ChartPie :data="absensi_report_all_by_ecommerce" :title="'Absensi Report by Ecommerce'" :height="250" />
        </div>
      </b-col>
    </b-row>
    <b-row class="justify-content-lg-start">
      <b-col sm="6" lg="4">
        <div class="pie-chart-containers">
          <ChartPie :data="absensi_report_all_by_kelas" :title="'Absensi Report by Kelas'" :height="600" />
        </div>
      </b-col>
    </b-row>
    <b-row class="justify-content-lg-start">
      <!--      <div id="app">-->
      <!--      <div class="logos">-->
      <!--        <img class="logo-hc" src="/img/brand/g2lab.png" alt="Highcharts Logo">-->
      <!--      </div>-->
      <!--      <h4>FINANCIAL PERFORMANCE CONSOLIDATION YTD JUNI’20</h4>-->
      <!--      <hr style="width: 200px; margin: 60px auto;">-->
      <!--      <h3>Select chart constructor:</h3>-->
      <!--      <div class="button-grp">-->
      <!--        <button @click="select('chart')" :class="{btnActive: selected === 'chart'}">Chart</button>-->
      <!--        <button @click="select('stockChart')" :class="{btnActive: selected === 'stockChart'}">Stock Chart</button>-->
      <!--        <button @click="select('mapChart')" :class="{btnActive: selected === 'mapChart'}">Map Chart</button>-->
      <!--      </div>-->
      <!--        <hr style="width: 200px; margin: 60px auto;">-->

      <b-col v-if="report_by_flag_loaded == true" sm="6" lg="3" v-for="(item, indexTraining) in this.list_report_by_flag" v-bind:key="item.id">
        <b-card no-body v-bind:class="item.name == 'Total' ? 'bg-primary' : 'bg-info'">
          <b-card-body class="pb-0">
            <h4 class="mb-0">
              {{ item.name }} Redeem: {{ item.data.current.Total | currency }} /
              {{ item.data.past.Total | currency }}
            </h4>
            <p>Current / Previous Month</p>
          </b-card-body>
          <card-bar-chart-redeem v-if="item.name == 'Total'" chartId="card-chart-01" class="chart-wrapper px-3" style="height: 70px" :height="70" :redeem_data="item.data" />
          <card-bar-chart-redeem v-else="" chartId="card-chart-02" class="chart-wrapper px-3" style="height: 70px" :height="70" :redeem_data="item.data" />
        </b-card>
      </b-col>
    </b-row>
    <b-row class="justify-content-lg-start">
      <b-col v-if="rekap_by_ecommerce_loaded == true" sm="6" lg="3" v-for="(item, indexTraining) in this.list_absensi_report_by_ecommerce" v-bind:key="item.id">
        <b-card no-body class="card" :style="item.name == 'Total' ? 'background-color: #299c14' : 'background-color: #72c659'">
          <b-card-body class="pb-0">
            <h4 class="mb-0">
              {{ item.name }} AbsensiReport: {{ item.data.current.Total | currency }} /
              {{ item.data.past.Total | currency }}
            </h4>
            <p>Current / Previous Month</p>
          </b-card-body>
          <card-bar-chart-absensi-report v-if="item.name == 'Total'" chartId="card-chart-03" class="chart-wrapper px-3" style="height: 70px" :height="70" :absensireport_data="item.data" />
          <card-bar-chart-absensi-report v-else="" chartId="card-chart-04" class="chart-wrapper px-3" style="height: 70px" :height="70" :absensireport_data="item.data" />
        </b-card>
      </b-col>

      <!--        <b-col sm="12" lg="12">-->
      <!--            <div v-if="performance_loaded == true">-->
      <!--                <bar  :data_performance="this.performance"></bar>-->
      <!--            </div>-->
      <!--        </b-col>-->
      <!--      </div>-->

      <!--      <b-col sm="6" lg="4" v-for="(item,indexTraining) in this.performance" v-bind:key="item.id" >-->
      <!--        <b-card no-body v-bind:class=" item.id=='image' ? 'bg-primary':'bg-info' ">-->
      <!--          <b-card-body class="pb-0">-->

      <!--            <h4 class="mb-0">{{item.data.annotation | currency}} / {{item.data.total | currency}} images</h4>-->
      <!--            <p>{{ item.name }} (With Annotation)</p>-->
      <!--          </b-card-body>-->
      <!--          <card-bar-chart-image-annotation v-if=" item.id=='image' " chartId="card-chart-01" class="chart-wrapper px-3" style="height:70px;" :height="70"/>-->
      <!--          <card-bar-chart-redeem v-else="" chartId="card-chart-02" class="chart-wrapper px-3" style="height:70px;" :height="70" :detailAnnotation="item.data.detail_annotation"/>-->
      <!--        </b-card>-->
      <!--      </b-col>-->

      <!--      <b-col v-if="dataTrainingsLoaded " sm="6" lg="12" v-for="(item,indexTraining) in this.performanceWithoutFirst" v-bind:key="item.id">-->
      <!--        <b-card no-body class="bg-info">-->
      <!--          <b-card-body class="pb-0">-->

      <!--            <h4 class="mb-0">Accuracy of List Trainings {{item.name}}</h4>-->
      <!--            <p>with {{item.data.total | currency}} images</p>-->
      <!--          </b-card-body>-->
      <!--          <card-bar-list-training  class="chart-wrapper px-3" style="height:210px;" :height="70" :dataTrainings="dataTrainings" :testingName="item.id"/>-->
      <!--        </b-card>-->
      <!--      </b-col>-->

      <!--      <b-col sm="6" lg="12">-->
      <!--        <b-card no-body class="bg-warning">-->
      <!--          <b-card-body class="pb-0">-->
      <!--            <b-dropdown class="float-right" variant="transparent p-0" right>-->
      <!--              <template slot="button-content">-->
      <!--                <i class="icon-settings"></i>-->
      <!--              </template>-->

      <!--              <b-dropdown-item v-for="(item,indexTraining) in dataTrainings" v-bind:key="item.id" @click="trainingActivate(indexTraining)">-->
      <!--                <div v-if="item.active==1" style="background-color:powderblue;">{{ item.name }} </div>-->
      <!--                <div v-else >{{ item.name }} </div>-->
      <!--                </b-dropdown-item>-->

      <!--            </b-dropdown>-->
      <!--            <div v-if="indexTrainingSelected > -1">-->
      <!--            <h4 class="mb-0" v-if="dataTrainings[indexTrainingSelected].active==1"> {{ dataTrainings[indexTrainingSelected].name +' (default)' }} </h4>-->
      <!--            <h4 class="mb-0" v-else > {{ dataTrainings[indexTrainingSelected].name  }} </h4>-->
      <!--            <div><a>Testing A : </a></div>-->
      <!--            <div>-->
      <!--              <a>Annotation : {{resultTestingObject.testing_a.onlyAnnotation.annotation }}</a></div>-->
      <!--              <a>Testing : {{resultTestingObject.testing_a.onlyAnnotation.testing }} </a>-->
      <!--              <p>Ketepatan : {{resultTestingObject.testing_a.onlyAnnotation.rekap.ketepatan.toFixed(2)}} %</p>-->
      <!--              <div v-for="(itemTesting,indexTesting) in ['testing_b','testing_c','testing_d','testing_bcd','testing_e','testing_f','testing_g','testing_z','testing_bcde','testing_bcdef','testing_bcdefg','testing_abcdefg']">-->
      <!--                  <div  v-if="resultTestingObject[itemTesting]" >-->
      <!--                  <div  v-if="resultTestingObject[itemTesting].onlyAnnotation" >-->
      <!--                  <div><a>{{itemTesting}} : </a></div>-->
      <!--                  <div>-->
      <!--                    <a v-if="resultTestingObject.testing_b.onlyAnnotation !== null " >Annotation : {{resultTestingObject[itemTesting].onlyAnnotation.annotation }}</a></div>-->
      <!--                    <a v-if="resultTestingObject.testing_b.onlyAnnotation !== null ">Testing : {{resultTestingObject[itemTesting].onlyAnnotation.testing }} </a>-->
      <!--                    <p v-if="resultTestingObject.testing_b.onlyAnnotation !== null ">Ketepatan : {{resultTestingObject[itemTesting].onlyAnnotation.rekap.ketepatan.toFixed(2)}} %</p>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--              <button v-if="dataTrainings[indexTrainingSelected].active==0"-->
      <!--                class="btn-pill btn-success"-->
      <!--                type="button"-->
      <!--                @click="jadikanDefault()" >-->
      <!--                Jadikan Default-->
      <!--              </button>-->
      <!--            </div>-->
      <!--          </b-card-body>-->
      <!--          <card-bar-chart-image-training v-if="indexTrainingSelected > -1" chartId="card-chart-03" class="chart-wrapper" style="height:270px;" height="270"/>-->
      <!--        </b-card>-->
      <!--      </b-col>-->
    </b-row>

    <div class="row" style="margin-top: 20px">
      <b-col sm="12" lg="12">
        <!--    <mdb-container>-->
        <!--      <b-row>-->
        <b>List Redeem</b>
        <vue-good-table
          mode="remote"
          :pagination-options="{
            // enabled: true,
            //mode: 'pages',
            perPage: 27,
            position: 'bottom',
            // perPageDropdown: [5, 10],
            dropdownAllowAll: false,
            // nextLabel: 'next',
            // prevLabel: 'prev',
            // rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of'
            // pageLabel: 'page', // for 'pages' mode
            // allLabel: 'All',
          }"
          :totalRows="total_record_redeem"
          :rows="list_redeem"
          :columns="columns"
          :sort-options="{
            enabled: false
          }"
          :row-style-class="rowStyleClassFn"
        >
          <template slot="table-row" slot-scope="props">
            <span>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
        <!--      </b-row>-->
        <!--      <mdb-row class="mdb-lightbox no-margin">-->

        <!--        <mdb-col v-for="(item,index) in dataImages" v-bind:key="index" md="4" @click.native="showLightbox(index)" >-->
        <!--          <figure>-->
        <!--            <img :src="item.url" class="img-fluid" alt="">-->
        <!--          </figure>-->
        <!--        </mdb-col>-->

        <!--      </mdb-row>-->
        <!--    </mdb-container>-->
      </b-col>
    </div>
  </div>
</template>

<script>
import { mdbContainer, mdbRow, mdbCol } from 'mdbvue'
import CardBarChartRedeem from './dashboard/CardBarChartRedeem'
import CardBarChartAbsensiReport from './dashboard/CardBarChartAbsensiReport'
import ChartRedeemReportMonthByDay from './dashboard/ChartRedeemReportMonthByDay'
import ChartReportMonthByWeek from './dashboard/ChartReportMonthByWeek'
import ChartReportEcommerceMonthByDay from './dashboard/ChartReportEcommerceMonthByDay'
import ChartReportEcommerceMonthByWeek from './dashboard/ChartReportEcommerceMonthByWeek'
import ChartPie from './dashboard/ChartPie'

export default {
  name: 'dashboard',
  mounted: function () {
    this.initData()
    this.getListRedeem()
    window.setInterval(() => {
      this.initData()
    }, 60000 * 5)
  },
  components: {
    CardBarChartAbsensiReport,
    CardBarChartRedeem,
    ChartRedeemReportMonthByDay,
    ChartReportMonthByWeek,
    ChartReportEcommerceMonthByDay,
    ChartReportEcommerceMonthByWeek,
    ChartPie,
    mdbContainer,
    mdbRow,
    mdbCol
  },
  data: function () {
    return {
      chartReportEcommerce: 'By Day',
      chartReportOptions: ['By Day', 'By Week'],
      isChartMonthActive: true,
      chartReportAbsensiEcommerce: 'By Day',
      chartReportAbsensiOptions: ['By Day', 'By Week'],
      isChartReportMonthActive: true,
      selected: 'chart',
      currentView: 'chart',
      total_record_redeem: 0,
      list_redeem: [],
      colorList: ['blue', 'red', 'green'],
      columns: [
        { field: 'id', label: 'Id' },
        { field: 'waktu', label: 'Waktu' },
        { field: 'kode', label: 'Kode' },
        { field: 'nama', label: 'Nama' },
        { field: 'hp', label: 'HP' },
        { field: 'email', label: 'Email' },
        { field: 'ecommerce', label: 'Ecommerce' },
        { field: 'kelas', label: 'Kelas' },
        { field: 'flag', label: 'Flag' },
        { field: 'batch', label: 'Batch' },
        { field: 'provinsi', label: 'Provinsi' }
      ],
      options: {
        limit: 17,
        page: 1,
        sort: ''
      },
      performance: {},
      performance_loaded: false,
      report_by_flag: {},
      list_report_by_flag: [],
      list_absensi_report_by_ecommerce: [],
      report_by_flag_loaded: false,
      rekap_by_ecommerce_loaded: false,
      redeemReportMonthByDay: { labels: [], current: [], past: [] },
      reddem_report_month_by_day_loaded: false,
      absensireportReportMonthByDay: { labels: [], current: [], past: [] },
      absensireport_report_month_by_day_loaded: false,
      redeem_report_month_by_day_loaded: false,
      shorter_report_rekap_loaded: false,
      shorter_rekap: { labels: ['clicked', 'not_clicked'], datasets: [4, 153] },
      absensi_report_all_by_status_kepesertaan: {},
      absensi_report_all_by_ecommerce: {},
      absensi_report_all_by_kelas: {},
      report_month_by_week_loaded: false,
      absensireportReportMonthByWeek: {},
      report_ecommerce_month_by_day_loaded: false,
      reportecommerceReportEcommerceMonthByDay: {
        labels: [],
        current: [],
        past: []
      },
      report_ecommerce_month_by_week_loaded: false,
      reportecommerceReportEcommerceMonthByWeek: {},
      performanceWithoutFirst: [],
      dataImages: [],
      dataTrainings: [],
      indexTrainingSelected: -1,
      resultTestingObject: {},
      dataTrainingsLoaded: false,
      visible: false,
      index: 0,
      // selected: 'Month',
      tableItems: [
        {
          avatar: { url: 'img/avatars/1.jpg', status: 'success' },
          user: {
            name: 'Yiorgos Avraamu',
            new: true,
            registered: 'Jan 1, 2015'
          },
          country: { name: 'USA', flag: 'us' },
          usage: { value: 50, period: 'Jun 11, 2015 - Jul 10, 2015' },
          payment: { name: 'Mastercard', icon: 'fa fa-cc-mastercard' },
          activity: '10 sec ago'
        },
        {
          avatar: { url: 'img/avatars/2.jpg', status: 'danger' },
          user: {
            name: 'Avram Tarasios',
            new: false,
            registered: 'Jan 1, 2015'
          },
          country: { name: 'Brazil', flag: 'br' },
          usage: { value: 22, period: 'Jun 11, 2015 - Jul 10, 2015' },
          payment: { name: 'Visa', icon: 'fa fa-cc-visa' },
          activity: '5 minutes ago'
        },
        {
          avatar: { url: 'img/avatars/3.jpg', status: 'warning' },
          user: { name: 'Quintin Ed', new: true, registered: 'Jan 1, 2015' },
          country: { name: 'India', flag: 'in' },
          usage: { value: 74, period: 'Jun 11, 2015 - Jul 10, 2015' },
          payment: { name: 'Stripe', icon: 'fa fa-cc-stripe' },
          activity: '1 hour ago'
        },
        {
          avatar: { url: 'img/avatars/4.jpg', status: '' },
          user: { name: 'Enéas Kwadwo', new: true, registered: 'Jan 1, 2015' },
          country: { name: 'France', flag: 'fr' },
          usage: { value: 98, period: 'Jun 11, 2015 - Jul 10, 2015' },
          payment: { name: 'PayPal', icon: 'fa fa-paypal' },
          activity: 'Last month'
        },
        {
          avatar: { url: 'img/avatars/5.jpg', status: 'success' },
          user: {
            name: 'Agapetus Tadeáš',
            new: true,
            registered: 'Jan 1, 2015'
          },
          country: { name: 'Spain', flag: 'es' },
          usage: { value: 22, period: 'Jun 11, 2015 - Jul 10, 2015' },
          payment: { name: 'Google Wallet', icon: 'fa fa-google-wallet' },
          activity: 'Last week'
        },
        {
          avatar: { url: 'img/avatars/6.jpg', status: 'danger' },
          user: {
            name: 'Friderik Dávid',
            new: true,
            registered: 'Jan 1, 2015'
          },
          country: { name: 'Poland', flag: 'pl' },
          usage: { value: 43, period: 'Jun 11, 2015 - Jul 10, 2015' },
          payment: { name: 'Amex', icon: 'fa fa-cc-amex' },
          activity: 'Last week'
        }
      ],
      tableFields: {
        avatar: {
          label: '<i class="icon-people"></i>',
          class: 'text-center'
        },
        user: {
          label: 'User'
        },
        country: {
          label: 'Country',
          class: 'text-center'
        },
        usage: {
          label: 'Usage'
        },
        payment: {
          label: 'Payment method',
          class: 'text-center'
        },
        activity: {
          label: 'Activity'
        }
      },
      siswaStatusOptions: [],
      siswaStatus: '',
      siswaKeOptions: [],
      siswaReferralCodeFilledOptions: [],
      siswaKe: '',
      siswaReferralCodeFilled: ''
    }
  },
  watch: {
    chartReportEcommerce: function (params) {
      params === 'By Day' ? (this.isChartMonthActive = true) : (this.isChartMonthActive = false)
    },
    chartReportAbsensiEcommerce: function (params) {
      params === 'By Day' ? (this.isChartReportMonthActive = true) : (this.isChartReportMonthActive = false)
    }
  },
  methods: {
    activate(elem) {
      this.selected = elem
    },
    handler() {
      var args = arguments
      for (var arg of args) {
        if (arg instanceof Function) {
          arg()
        }
      }
    },
    select(elem) {
      this.currentView = elem
      this.activate(elem)
    },
    variant(value) {
      let $variant
      if (value <= 25) {
        $variant = 'info'
      } else if (value > 25 && value <= 50) {
        $variant = 'success'
      } else if (value > 50 && value <= 75) {
        $variant = 'warning'
      } else if (value > 75 && value <= 100) {
        $variant = 'danger'
      }
      return $variant
    },
    flag(value) {
      return 'flag-icon flag-icon-' + value
    },
    rowStyleClassFn(row) {
      let result = 'default'
      if (row.status_id == 1) {
        result = 'green'
      } else if (row.status_id == 2) {
        result = 'red'
      }
      return result
    },
    showLightbox(index) {
      this.index = index
      this.visible = true
    },
    handleHide() {
      this.visible = false
    },
    initData() {
      this.counterProgress = 0
      this.getRedeemReportByFlag()
      this.getAbsensireportRekapByEcommerce()
      this.getRedeemReportMonthByDay()
      this.getAbsensireportReportMonthByDay()
      this.getShorterReportRekap()
      this.getAbsensiReportAll()
      this.getAbsensiReportByEcommerceAll()
      this.getAbsensiReportByKelasAll()
      this.getRekapStatusEcommerce()
      this.getRekapKe()
      this.getReportMonthByWeek()
      this.getReportEcommerceMonthByWeek()
      this.getReportEcommerceMonthByDay()
      this.getRekapReferralCodeFilled()
    },
    getRedeemReportByFlag() {
      this.report_by_flag_loaded = false
      this.$store.dispatch('reedem/GET_REPORT_BY_FLAG').then(() => {
        let temp = this.$store.getters['reedem/report_by_flag'] ? this.$store.getters['reedem/report_by_flag'] : {}
        this.report_by_flag = temp
        this.list_report_by_flag = []
        let sortedKeys = Object.keys(temp)
        for (var j = 0; j < sortedKeys.length; j++) {
          this.list_report_by_flag.push({
            name: sortedKeys[j],
            data: temp[sortedKeys[j]]
          })
        }
        this.report_by_flag_loaded = true
      })
    },
    getRedeemReportMonthByDay() {
      this.redeem_report_month_by_day_loaded = false
      this.$store.dispatch('reedem/GET_REPORT_MONTH_BY_DAY').then(() => {
        let temp = this.$store.getters['reedem/report_month_by_day'] ? this.$store.getters['reedem/report_month_by_day'] : {}
        this.redeemReportMonthByDay = temp.chart
        this.redeem_report_month_by_day_loaded = true
      })
    },
    getShorterReportRekap() {
      this.shorter_report_rekap_loaded = false
      this.$store.dispatch('shorter/GET_REPORT_REKAP').then(() => {
        this.shorter_rekap = this.$store.getters['shorter/report_rekap'] ? this.$store.getters['shorter/report_rekap'].datasets : { labels: [], datasets: [] }
        this.shorter_report_rekap_loaded = true
      })
      // this.shorter_rekap.dataset[0] = this.$store.getters[
      //   "shorter/report_rekap"
      // ].clicked;
      // this.shorter_rekap.dataset[1] = this.$store.getters[
      //   "shorter/report_rekap"
      // ].not_clicked;
    },
    getAbsensireportReportMonthByDay() {
      this.absensireport_report_month_by_day_loaded = false
      this.$store.dispatch('absensireport/GET_REPORT_MONTH_BY_DAY').then(() => {
        let temp = this.$store.getters['absensireport/report_month_by_day'] ? this.$store.getters['absensireport/report_month_by_day'] : {}
        this.absensireportReportMonthByDay = temp.chart
        this.absensireport_report_month_by_day_loaded = true
      })
    },
    getAbsensireportRekapByEcommerce() {
      this.rekap_by_ecommerce_loaded = false
      this.$store.dispatch('absensireport/GET_REKAP_BY_ECOMMERCE').then(() => {
        let temp = this.$store.getters['absensireport/rekap_by_ecommerce'] ? this.$store.getters['absensireport/rekap_by_ecommerce'] : {}
        this.report_by_flag = temp
        this.list_absensi_report_by_ecommerce = []
        let sortedKeys = Object.keys(temp)
        for (var j = 0; j < sortedKeys.length; j++) {
          this.list_absensi_report_by_ecommerce.push({
            name: sortedKeys[j],
            data: temp[sortedKeys[j]]
          })
        }
        this.rekap_by_ecommerce_loaded = true
      })
    },
    getAbsensiReportAll() {
      this.$store.dispatch('absensireport/GET_REKAP_STATUS_ALL').then(() => {
        this.absensi_report_all_by_status_kepesertaan = this.$store.getters['absensireport/rekap_status_all']
          ? this.$store.getters['absensireport/rekap_status_all'].datasets
          : { labels: [], datasets: [] }
      })
    },
    getAbsensiReportByEcommerceAll() {
      this.$store.dispatch('absensireport/GET_REKAP_BY_ECOMMERCE_ALL').then(() => {
        this.absensi_report_all_by_ecommerce = this.$store.getters['absensireport/rekap_by_ecommerce_all']
          ? this.$store.getters['absensireport/rekap_by_ecommerce_all'].datasets
          : { labels: [], datasets: [] }
      })
    },
    getAbsensiReportByKelasAll() {
      this.$store.dispatch('absensireport/GET_REKAP_BY_KELAS_ALL').then(() => {
        this.absensi_report_all_by_kelas = this.$store.getters['absensireport/rekap_by_kelas_all'] ? this.$store.getters['absensireport/rekap_by_kelas_all'].datasets : { labels: [], datasets: [] }
      })
    },
    getRekapStatusEcommerce() {
      this.$store.dispatch('absensireport/GET_REKAP_STATUS_ECOMMERCE').then(() => {
        this.siswaStatusOptions = []
        Object.entries(this.$store.getters['absensireport/rekap_by_status_ecommerce'].datasets.datasets).forEach((datasets) => {
          this.siswaStatusOptions.push({
            value: {
              datasets: datasets[1],
              labels: this.$store.getters['absensireport/rekap_by_status_ecommerce'].datasets.labels,
              id: datasets[0]
            },
            text: datasets[0]
          })
        })
        this.siswaStatus = this.siswaStatusOptions[1].value
      })
    },
    getRekapKe() {
      this.$store.dispatch('absensireport/GET_REKAP_KE').then(() => {
        this.siswaKeOptions = []
        Object.entries(this.$store.getters['absensireport/rekap_by_ke'].datasets.datasets).forEach((datasets) => {
          this.siswaKeOptions.push({
            value: {
              datasets: datasets[1],
              labels: this.$store.getters['absensireport/rekap_by_ke'].datasets.labels,
              id: datasets[0]
            },
            text: datasets[0]
          })
        })
        this.siswaKe = this.siswaKeOptions[1].value
      })
    },
    getRekapReferralCodeFilled() {
      this.$store.dispatch('absensireport/GET_REKAP_REFERRAL_CODE_FILLED').then(() => {
        this.siswaReferralCodeFilledOptions = []
        Object.entries(this.$store.getters['absensireport/rekap_by_referral_code_filled'].datasets.datasets).forEach((datasets) => {
          this.siswaReferralCodeFilledOptions.push({
            value: {
              datasets: datasets[1],
              labels: this.$store.getters['absensireport/rekap_by_referral_code_filled'].datasets.labels,
              id: datasets[0]
            },
            text: datasets[0]
          })
        })
        this.siswaReferralCodeFilled = this.siswaReferralCodeFilledOptions[1].value
      })
    },
    getReportMonthByWeek() {
      this.report_month_by_week_loaded = false
      this.$store.dispatch('absensireport/GET_REPORT_MONTH_BY_WEEK').then(() => {
        let temp = this.$store.getters['absensireport/report_month_by_week'] ? this.$store.getters['absensireport/report_month_by_week'] : {}
        this.absensireportReportMonthByWeek = temp.chart
        this.report_month_by_week_loaded = true
      })
    },
    getReportEcommerceMonthByDay() {
      this.report_ecommerce_month_by_day_loaded = false
      this.$store.dispatch('reportecommerce/GET_REPORT_ECOMMERCE_MONTH_BY_DAY').then((reportecommerceReportEcommerceMonthByDay) => {
        let temp = reportecommerceReportEcommerceMonthByDay.data.data ? reportecommerceReportEcommerceMonthByDay.data.data : {}
        this.reportecommerceReportEcommerceMonthByDay = temp.chart
        this.report_ecommerce_month_by_day_loaded = true
      })
    },
    getReportEcommerceMonthByWeek() {
      this.report_ecommerce_month_by_week_loaded = false
      this.$store.dispatch('reportecommerce/GET_REPORT_ECOMMERCE_MONTH_BY_WEEK').then(() => {
        let temp = this.$store.getters['reportecommerce/report_ecommerce_month_by_week'] ? this.$store.getters['reportecommerce/report_ecommerce_month_by_week'] : {}
        this.reportecommerceReportEcommerceMonthByWeek = temp.chart

        this.report_ecommerce_month_by_week_loaded = true
      })
    },
    getListRedeem() {
      this.$store
        .dispatch('reedem/GET_LIST_REEDEM', {
          masterType: this.$route.meta.name,
          params: ''
        })
        .then(() => {
          this.list_redeem = this.$store.getters['reedem/list_reedem'] ? this.$store.getters['reedem/list_reedem'] : []
          this.total_record_redeem = this.$store.getters['reedem/paginate'] ? this.$store.getters['reedem/paginate'].total : 0
        })
    },

    scroll() {
      window.onscroll = () => {
        // let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight
        // if (bottomOfWindow) {
        //  this.onNextPage()
        // }
      }
    }
  }
  // computed: {
  //
  // }
}
</script>

<style scoped>
.pie-chart-container {
  display: flex;
  margin: 30px 0;
  justify-items: center;
  justify-content: space-evenly;
}
.pie-byday {
  display: flex;
  margin-left: 30%;
  justify-items: center;
}
.pie-chart-container-flex {
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  justify-items: center;
  justify-content: space-evenly;
}
.pie-chart-containers {
  display: flex;
  margin: 50px 0;
  justify-items: center;
  justify-content: space-evenly;
}
@media (min-width: 768px) {
  .carousel-multi-item-2 .col-md-3 {
    float: left;
    width: 25%;
    max-width: 100%;
  }
}

.carousel-multi-item-2 .card img {
  border-radius: 2px;
}

figure {
  cursor: pointer;
}

h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.row > * {
  padding: 0 20px;
}
.logos {
  margin: 0 auto;
  width: 50%;
}
.logo-hc {
  width: 70%;
  height: auto;
}
.logo-vue {
  width: 15%;
  height: auto;
}
.button-grp {
  margin: 20px 0;
}
button {
  padding: 10px 20px;
  outline: none;
  background: #828ea0;
  color: #fff;
  font-size: 16px;
  margin: 5px;
  border: 0px;
}
button:hover {
  background: #a7aeb8;
  transition: background 0.2s;
}
button:active {
  color: rgb(117, 117, 117);
}
.btnActive {
  color: #6fcd98;
}
select {
  background: #e0e0e0 !important;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}
option:not(:checked) {
  background-color: white;
  color: #000;
}
</style>
